import {ApiGeoResponse, AutocompleteResponseEvent, WhitelabelEvent} from "../../common/ts/events/Events";
import {EventBus} from "../../common/ts/events/EventBus";

// whitelabel-brand/hipflat/src/main/kotlin/com/lifullconnect/whitelabel/brand/hipflat/api/geo/HipflatAutocompleteSuggestion.kt
// HipflatAutocompleteSuggestionCategoryId
enum HipflatAutocompleteSuggestionCategoryId {
    AREAS = 1,
    PROJECTS = 2,
}

// whitelabel-brand/hipflat/src/main/kotlin/com/lifullconnect/whitelabel/brand/hipflat/api/geo/HipflatAutocompleteSuggestion.kt
interface HipflatApiGeoResponse {
    id: number;
    name: string;
    url_name: string;
    parent_names: string[];
    geo_level_name: string;
    categoryText: string;
    categoryId: HipflatAutocompleteSuggestionCategoryId;
}

EventBus.getInstance().subscribe(AutocompleteResponseEvent.TYPE, (event: WhitelabelEvent) => {
    const autocompleteResponseEvent: AutocompleteResponseEvent = event as AutocompleteResponseEvent
    const body: ApiGeoResponse[] = autocompleteResponseEvent.payload.body;
    const {
        insertAdjacentHTML,
        generateTextSuggestionElement,
        showAutocompleteDataList,
    } = autocompleteResponseEvent.payload.callbacks;
    const {
        textSuggestionCssClass,
        textSuggestionTitleCssClass,
        focusCssClass,
    } = autocompleteResponseEvent.payload.cssClassNames;
    const categories = body.reduce((acc: { [key: string]: HipflatApiGeoResponse[]; }, curr) => {
        const hipflatApiGeoResponse: HipflatApiGeoResponse = curr as HipflatApiGeoResponse;
        const categoryId: string = hipflatApiGeoResponse.categoryText;
        if (!acc[categoryId]) {
            acc[categoryId] = [];
        }
        acc[categoryId].push(hipflatApiGeoResponse);
        return acc;
    }, {});
    let counter = 0;
    Object.entries(categories).forEach(([category, suggestions]) => {
        if (suggestions.length > 0) {
            const elementType: string = `<div class="${textSuggestionCssClass} ${textSuggestionTitleCssClass}">${category}</div>`;
            insertAdjacentHTML(elementType);
            suggestions.forEach((suggestion) => {
                const focusClassName = counter++ == 0 ? ` ${focusCssClass}` : "";
                //language=HTML
                const html = `
                    <div class="${textSuggestionCssClass}${focusClassName}">
                        <div class="text">
                            <span class="principal">${suggestion.name}</span>
                            <span class="secondary">${suggestion.parent_names.join(', ')}</span>
                        </div>
                        <div class="type">${suggestion.geo_level_name}</div>
                    </div>
                `;
                generateTextSuggestionElement(
                    html,
                    {
                        id: suggestion.id.toString(),
                        name: suggestion.name,
                        completeText: "",
                        isProject: suggestion.categoryId === HipflatAutocompleteSuggestionCategoryId.PROJECTS
                    }
                );
            });
            showAutocompleteDataList();
        }
    });
});